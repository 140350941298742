import React, { useState, useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { QueryOptions } from '../../helpers/query.options'
import { branchService } from '../../models/branch/service'
import { Branch } from '../../models/branch/branch'
import { BranchItem } from '../Branch/BranchItem'
import { Title } from '../Typography/Title'
import { GoogleMapContainer } from '../map/GoogleMap'


export const OurBranches = (props: any) => {
    const { app, setConfirmation, setSnackBar, translations, language } = props
    const [branches, setBranches] = useState<Branch[]>([])
    const [branch, setBranch] = useState<Branch | null>()
    const [activeBranch, setActiveBranch] = useState()

    useEffect(() => {
        loadBranches()
    }, [])

    const loadBranches = () => {
        const options = new QueryOptions()
        options.lang = language
        options.pageSize = 40
        branchService.listWithCounter(options).then((resp: any) => {
            setBranches(resp.current)
        })
    }
    return <Box sx={{ width: '100%', alignItems: 'flex-start', display: 'flex', flexDirection: 'column', padding: window.innerWidth < 1200 ? 0 : 2 }}>

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'space-between', justifyContent: 'space-between', width: window.innerWidth < 1200 ? '100%' : '96%', padding: 0 }}>
            <Grid container sx={{ padding: window.innerWidth < 1200 ? 0 : 2 }}>
                {window.innerWidth < 1200 ? <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'space-between', justifyContent: 'space-between', width: '90%', padding: 2, paddingTop: 15, marginLeft:2}}>
                    <Title         
                        fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                        fontSize={24}
                        uppercase={true}
                        className="roboto"
                        fontWeight={800}
                        text={translations?.our_branches} />
                </div> : <></>}
                <Box sx={{ padding: 0, height: 500, width: '100%', paddingTop: window.innerWidth < 1200 ? 8 : 0, }}>
                    <GoogleMapContainer branches={branches} translations={translations} activeBranch={activeBranch} />
                </Box>
                {window.innerWidth > 1200 ? <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'space-between', justifyContent: 'space-between', width: '90%', padding: 2, paddingTop: 22 }}>
                    <Title
                        fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                        fontSize={18}
                        uppercase={true}
                        className="roboto"
                        fontWeight={800}
                        text={translations?.our_branches} />
                </div> : <></>}
                {branches?.map((item, index) => {
                    return <Grid item lg={6} sm={6} xs={12} padding={2}>
                        <BranchItem
                            key={index}
                            setActiveBranch={setActiveBranch}
                            language={language}
                            item={item} onClicked={() => {
                            }} />
                    </Grid>
                })}
            </Grid>

        </div>
        <Box sx={{ paddingTop: 12, width: '94%' }}></Box>
    </Box>
}