import { Box, Button } from "@mui/material";
import React, { useState, useEffect, Fragment } from "react";
import { Title } from "../Typography/Title";
import { Animated } from "react-animated-css";

export const Configurables = (props) => {
    const { item, simple, setSimple, animate, language, translations } = props

    const getConfigurableName = () => {
        if (item.configurables?.length) {
            const [config] = item.configurables
            return config.name.value
        }
    }
    const getConfigurableId = () => {
        if (item.configurables?.length) {
            const [config] = item.configurables
            return config.name.id
        }
    }

    useEffect(() => {
        if (item?.configurables?.length) {
            const [conf] = item?.configurables?.sort((n1: any, n2: any) => {
                const n1Price = getPrice(n1.id, item)
                const n2Price = getPrice(n2.id, item)
                if (n1Price > n2Price) {
                    return 1;
                } if (n1Price < n2Price) { return -1; } return 0;
            })
            if (conf) {
                const [simple] = item.simpleProducts.filter(i => i.attributes.map(at => at.id).indexOf(conf.id) > -1)
                if (simple) {
                    setSimple(simple)
                }
            }
        }
    }, [item?.configurables])

    const getPrice = (id, product) => {
        if (!product) {
            return 0
        }
        const [simple] = item.simpleProducts.filter(i => i.attributes.map(at => at.id).indexOf(id) > -1)
        return simple ? simple.specialPrice : 0
    }

    return <Box>
        {item?.configurables?.length > 0 && <Animated animationIn="shake" animationOut="fadeIn"
            animationInDuration={1200}
            isVisible={animate}>
            <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 5 }}>
                <Title
                    uppercase={true}
                    fontWeight={'400'}
                    fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                    color={'#222'}
                    fontSize={14}
                    text={`${translations?.choose} ${getConfigurableName()}`} />
            </div>
        </Animated>}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
            {item?.configurables?.sort((n1: any, n2: any) => {
                const n1Price = getPrice(n1.id, item)
                const n2Price = getPrice(n2.id, item)
                if (n1Price > n2Price) {
                    return 1;
                } if (n1Price < n2Price) { return -1; } return 0;
            }).map((attr: any, index: number) => {
                return getConfigurableId() !== 'color_family' ? <Button
                    key={index}
                    sx={{
                        color: simple?.attributes.map(s => s.id).indexOf(attr.id) > -1 ? 'white' : '#333',
                        marginRight: 1,
                        backgroundColor: simple?.attributes.map(s => s.id).indexOf(attr.id) > -1 ? '#333' : 'white',
                        '&:hover': {
                            color: simple?.attributes.map(s => s.id).indexOf(attr.id) > -1 ? 'white' : '#333',
                            backgroundColor: simple?.attributes.map(s => s.id).indexOf(attr.id) > -1 ? '#333' : 'white',
                        },
                        marginBottom: 1,
                        justifyContent: 'space-around',
                        height: 30,
                        border: '1px solid #333',
                        borderRadius: 2,

                    }}
                    disableRipple={true}
                    disableFocusRipple={false}
                    color={"secondary"}
                    onClick={() => {
                        const [simple] = item.simpleProducts.filter(i => i.attributes.map(at => at.id).indexOf(attr.id) > -1)
                        if (simple) {
                            setSimple(simple)
                        }
                    }}
                >
                    <Title
                        uppercase={true}
                        fontWeight={'500'}
                        fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                        color={simple?.attributes.map(s => s.id).indexOf(attr.id) > -1 ? 'white' : 'black'}
                        fontSize={13}
                        style={{ marginRight: 10 }}
                        text={attr.value} />
                    <Title
                        uppercase={true}
                        fontWeight={'500'}
                        fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                        color={simple?.attributes.map(s => s.id).indexOf(attr.id) > -1 ? 'white' : 'black'}
                        fontSize={13}
                        text={`${getPrice(attr.id, item)} ₾`} />

                </Button> : <Box
                    onClick={() => {
                        const [simple] = item.simpleProducts.filter(i => i.attributes.map(at => at.id).indexOf(attr.id) > -1)
                        if (simple) {
                            setSimple(simple)
                        }
                    }}
                    style={{
                        display: 'inline-block',
                        alignSelf: 'left',
                        backgroundColor: `#${attr.value}`, width: 20, height: 20, borderWidth: 1, border:
                            simple?.attributes.map(s => s.id).indexOf(attr.id) > -1 ? '1px solid #000' : '1px solid #ddd',
                        borderRadius: 3,
                        margin: 2, cursor: 'pointer'
                    }}></Box>
            })}
            
        </div>
        {simple && <Title
                uppercase={true}
                fontWeight={'500'}
                style={{marginTop: 10}}
                fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                color={'#222'}
                fontSize={14}
                text={`${simple.name}`} />}
    </Box>



}