import React, { Fragment, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Divider, Fade } from '@mui/material';
import { connect } from 'react-redux';
import { setCart, setSnackBar, setConfirmation } from '../../models/application/applicationActions';
import { Transition } from '../../helpers/utilities';
import { makeStyles } from '@mui/styles';
import { COLORS } from '../../constants/colors';
import { Title } from '../Typography/Title';
import '../styles.scss'
import { environment } from '../../environment/environment';
import GiftCardForm from '../GiftCard/GiftCardForm';
import { BlackButton } from '../Button/BlackButton';

const useStyles = makeStyles({
    rootRow: {
        width: '100%',
        padding: 0,
        marginTop: 0,
        height: window.innerHeight,
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
    }
})

const GiftCardFormDialog = (props) => {
    const {
        app,
        giftCard,
        createGiftCard,
        beigeLoaded,
        language,
        address,
        cities,
        options,
        translations } = props
    const [open, setOpen] = useState(false);

    const classes = useStyles()
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return <Fragment>
        <Box style={{ paddingTop: 3, position: 'relative' }}>
            <Box style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: 10, paddingBottom: 120 }}>
                <Title text={`${translations.e_gift_card_purchase}`} fontSize={18} uppercase={true} fontWeight='600' color={"#444"} style={{ marginBottom: 20 }} />
                <Fade
                    in={beigeLoaded}
                    style={{ transformOrigin: '0 0 0', width: '100%' }}
                    {...(beigeLoaded ? { timeout: 2000 } : {})}
                >
                    <img src={`${environment.basePath}/assets/catalog/product/beige.jpeg`} style={{ width: window.innerWidth - 20 }} />
                </Fade>
                <Title text={`${translations.gift_card_purchase_text}`} fontSize={16} uppercase={false} fontWeight='400' color={"#444"} style={{ marginTop: 20, width: window.innerWidth - 20 }} />
                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10,width: '90%', height: 60, marginTop: 20 }}>
                    <BlackButton
                        onClick={handleClickOpen}
                        title={translations?.add}
                    />
                </Box>
            </Box>
        </Box>

        <Dialog
            maxWidth='md'
            style={{
                padding: 0, 
                minWidth: window.innerWidth > 1200 ? 500 : '100%',
                overflow: 'hidden',
                minHeight: window.innerHeight
            }}
            sx={{
                '&.MuiDialog-paperScrollPaper': {
                    maxHeight: 1200
                }
            }}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <div style={{ position: 'absolute', right: window.innerWidth * .03, top: 10, cursor: 'pointer' }} onClick={handleClose}>
                <CloseIcon />
            </div>
            <Box style={{ padding: 10, backgroundColor: COLORS.BODY_BG, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Divider style={{ marginTop: 30, marginBottom: 30, color: 'white' }} />
                {giftCard && <Box style={{ display: 'flex', flexDirection: 'column', maxWidth: window.innerWidth }}>
                    <Box style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: 10 }}>
                        <Title text={`${translations.e_gift_card_purchase}`} fontSize={18} uppercase={true} fontWeight='600' color={"#444"} style={{ marginBottom: 20 }} />
                        <Fade
                            in={beigeLoaded}
                            style={{ transformOrigin: '0 0 0', width: '100%' }}
                            {...(beigeLoaded ? { timeout: 2000 } : {})}
                        >
                            <img src={`${environment.basePath}/assets/catalog/product/beige.jpeg`} style={{ width: window.innerWidth - 20 }} />
                        </Fade>
                        <Title text={`${translations.gift_card_purchase_text}`} fontSize={16} uppercase={false} fontWeight='400' color={"#444"} style={{ marginTop: 20, width: window.innerWidth - 20 }} />
                    </Box>
                    <Box style={{ display: 'flex', flexDirection: 'column', width: '65%', backgroundColor: 'transparent', paddingLeft: 10 }}>
                        <GiftCardForm
                            loc="mobile"
                            cities={cities}
                            translations={translations}
                            {...props}
                            address={address}
                            options={options}
                            product={giftCard}
                            onAddToCart={(values) => {
                                createGiftCard(values, language)
                                handleClose()
                            }}
                        />
                    </Box>
                </Box>}
                <Divider style={{ marginTop: 30, marginBottom: 30 }} />

            </Box>
        </Dialog>
    </Fragment>
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app,
        translations: state.translations,
        language: state.language
    }
}

export default connect(mapStateToProps, {
    setCart,
    setSnackBar,
    setConfirmation
})(GiftCardFormDialog)
