import React, { useState, useEffect } from 'react'
import { Box } from "@mui/material"
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { Title } from '../Typography/Title';
import PlaceIcon from '@mui/icons-material/Place';
export const BranchItem = (props) => {
    const { item, onClicked, language, setActiveBranch } = props
    const iconSize = 20
    const fontSizeSize = 18
    const RenderRows = (item) => {
        if (item) {
            return [
                { text: item.address.street, icon: <LocationOnOutlinedIcon sx={{ fontSize: iconSize }} />, fontSize: 16, uppercase: true, fontWeight: '500', item: item },
                { text: item.phone, icon: <CallOutlinedIcon sx={{ fontSize: iconSize }} />, fontSize: 16, uppercase: false, fontWeight: '300' },
                { text: item.workingHours, icon: <QueryBuilderIcon sx={{ fontSize: iconSize }} />, fontSize: 16, uppercase: false, fontWeight: '300' }
            ]
        } else {
            return []
        }
    }
    return <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {RenderRows(item).map((item, index) => {
            return <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ width: '10%', alignItems: 'flex-start', justifyContent: '' }}>{item.icon}</Box>
                <Box sx={{ width: '90%', alignItems: 'flex-start', justifyContent: '', display: 'flex', fontSize: 15 }}>
                    <Title
                        fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                        text={item.text}
                        fontSize={item.fontSize}
                        uppercase={item.uppercase}
                        className="roboto"
                        fontWeight={item.fontWeight} />
                </Box>
                {index === 0 ? <button onClick={() => {
                    window.scrollTo({ top: 0 })
                    setActiveBranch(item.item)
                }} style={{ border: 0, cursor: 'pointer' }}><PlaceIcon /></button> : ""}
            </Box>
        })}
    </Box>
}