import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik, ErrorMessage } from "formik";
import { connect } from 'react-redux'
import { validationSchemas } from "../../helpers/validations";
import { setSnackBar } from '../../models/application/applicationActions'
import { NameIdItem, PasswordRestore } from "../../models/common/common";
import { forgotPasswordService, passwordRestoreService } from "../../models/common/service";
import TermsDialog from './TermsDialog'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Button } from "@mui/material";
import { COLORS } from "../../constants/colors";


const ForgotPasswordFormDialog = (props: any) => {
    const { onOpen, onClose, translations } = props
    const [termsOpen, setTermsOpen] = React.useState(false)
    const [tokenMode, setTokenMode] = useState(false)
    const [notFound, setNotFound] = useState(false)
    const [tokenInvalid, setTokenInvalid] = useState(false)
    let error: string = null
    const [open, setOpen] = useState(false as any)
    const handleCloseDialog = () => {
        setTokenMode(false)
        onClose(false)
    }
    const email_required = `${translations?.email} ${translations?.is_required}`
    const password_required = `${translations?.password} ${translations?.is_required}`
    const password_confirmation_required = `${translations?.password_confirmation} ${translations?.is_required}`
    const token_required = `${translations?.token} ${translations?.is_required}`
    const validation = Yup.object().shape({
        email: Yup.string().required(email_required)

    })
    const handleClose = () => {
        onClose(false)
    }
    useEffect(() => {
        setOpen(onOpen)
    }, [onOpen])

    const validationRestore = Yup.object().shape({
        email: Yup.string().required(email_required),
        token: Yup.string().required(token_required),
        password: Yup.string().required(password_required),
        confirmpassword: Yup.string().required(password_confirmation_required).when("password", {
            is: (val: any) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("password")],
                translations?.passwords_dont_coincide
            )
        })
    })

    const getValues = (values) => {
        const nameIdItem = new NameIdItem()
        nameIdItem.id = values.email
        forgotPasswordService.create(nameIdItem).then((resp: any) => {
            if (resp?.status === 402) {
                setNotFound(true)
            }
            if (resp?.id) {
                setTokenMode(true)
            }
        })
    }
    const getValuesRestore = (values) => {
        const item = new PasswordRestore()
        item.email = values.email
        item.password = values.password
        item.token = values.token
        passwordRestoreService.create(item).then((resp: any) => {
            if (resp?.status === 402) {
                setTokenInvalid(true)
            }
          
            if (resp?.token) {
                onClose(false)
                props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text: translations?.password_updated_successful, open: true })
                setTimeout(() => {
                    props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                }, 4000)
            }
        });
    }

    return (
        <Dialog open={props.onOpen}
            onClose={(_, reason) => {
                if (reason !== "backdropClick") {
                    handleClose();
                }
            }}
            aria-labelledby="form-dialog-title" >
            <DialogTitle id="form-dialog-title">{translations?.restore_password}</DialogTitle>
            <DialogContent className="dialog">
                {!tokenMode && <Formik
                    validationSchema={validation}
                    initialValues={{ email: '' }}
                    onSubmit={getValues}>
                    {({ values, handleSubmit, errors, touched, validateForm, setFieldValue, handleChange, handleBlur }: any) => (
                        <Form onSubmit={(event) => {
                            handleSubmit(event)
                        }}>
                            <Grid container style={{ width: 300 }}>
                                <Grid item lg={12} spacing={3} xs={12}>
                                    <TextField
                                        style={{ width: '90%', marginTop: 10 }}
                                        id="outlined-basic"
                                        label={translations?.email}
                                        variant="outlined"
                                        type="text"
                                        name="email"
                                        onChange={(value) => {
                                            setNotFound(false)
                                            handleChange(value)
                                        }}
                                        onBlur={handleBlur}
                                        value={values.personalId}
                                    />

                                </Grid>
                                <Grid item lg={12} spacing={3}>
                                    {notFound && <span className="error">
                                        {translations?.user_not_found}

                                    </span>}
                                    <ErrorMessage name="personalId" >
                                        {msg => <span className="error">{msg}</span>}
                                    </ErrorMessage>
                                </Grid>
                            </Grid>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} sx={{
                                    backgroundColor: 'gray',
                                    color: 'white',
                                    '&:hover': {
                                        color: 'white',
                                        backgroundColor: COLORS.BG_GRAY_PURPLE,
                                    }
                                }}>
                                    {translations?.cancel}
                                </Button>
                                <Button
                                    type="submit"
                                    sx={{
                                        backgroundColor: 'black',
                                        color: 'white',
                                        '&:hover': {
                                            color: 'black',
                                            backgroundColor: 'gray',
                                        }
                                    }}>{translations?.send}
                                </Button>


                            </DialogActions>
                        </Form>


                    )}
                </Formik>}
                {tokenMode && <Formik
                    validationSchema={validationRestore}
                    initialValues={{ personalId: '' }}
                    onSubmit={getValuesRestore}>
                    {({ values, handleSubmit, errors, touched, validateForm, setFieldValue, handleChange, handleBlur }: any) => (
                        <Form onSubmit={(event) => {
                            const [e] = Object.keys(errors);
                            if (e) {
                                error = e
                            } else {
                                error = null
                            }
                            if (!Object.keys(errors).length) {
                                handleSubmit(event)
                            }
                        }}>
                            <Grid container >
                                {tokenMode && <Grid item lg={6}>
                                    <TextField
                                        style={{ width: '90%', marginTop: 10 }}
                                        id="outlined-basic"
                                        value={values.token}
                                        label={translations?.four_digit_code}
                                        variant="outlined"
                                        type="number"
                                        name="token"
                                        onChange={(event) => {
                                            
                                            setFieldValue("token", event.target.value)
                                        }}
                                        onBlur={handleBlur}
                                    />
                                </Grid>}
                                <Grid item lg={6} spacing={3}>
                                    <TextField
                                        style={{ width: '90%', marginTop: 10 }}
                                        id="outlined-basic"
                                        label={translations?.email}
                                        variant="outlined"
                                        type="text"
                                        name="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                    />
                                    <ErrorMessage name="personalId" >
                                        {msg => <span className="error">{msg}</span>}
                                    </ErrorMessage>
                                </Grid>
                                <Grid item lg={6} spacing={3} className="text-field">
                                    <TextField
                                        style={{ width: '90%', marginTop: 10 }}
                                        id="outlined-basic"
                                        label={translations?.password}
                                        variant="outlined"
                                        type="password"
                                        name="password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                    />
                                    <ErrorMessage name="password" >
                                        {msg => <span className="error">{msg}</span>}
                                    </ErrorMessage>
                                </Grid>
                                <Grid item lg={6} spacing={3}>
                                    <TextField
                                        style={{ width: '90%', marginTop: 10 }}
                                        id="outlined-basic"
                                        label={translations?.password_confirmation}
                                        variant="outlined"
                                        type="password"
                                        name="confirmpassword"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.confirmpassword}
                                    />
                                    <ErrorMessage name="confirmpassword" >
                                        {msg => <span className="error">{msg}</span>}
                                    </ErrorMessage>
                                </Grid>
                                <Grid item lg={12} spacing={3}>
                                    {tokenInvalid && <span className="error">
                                        {translations?.wrong_credentials}

                                    </span>}
                                    {error && <span className="error">
                                        {translations?.fill_all_fields}
                                    </span>}

                                </Grid>
                            </Grid>
                            <DialogActions>
                                <Button
                                    onClick={handleCloseDialog}
                                    sx={{
                                        backgroundColor: 'gray',
                                        color: 'white',
                                        '&:hover': {
                                            color: 'white',
                                            backgroundColor: COLORS.BG_GRAY_PURPLE,
                                        }
                                    }}>{translations?.cancel}
                                </Button>
                                <Button
                                    type="submit"
                                    sx={{
                                        backgroundColor: 'black',
                                        color: 'white',
                                        '&:hover': {
                                            color: 'black',
                                            backgroundColor: 'gray',
                                        }
                                    }}>{translations?.send}
                                </Button>

                            </DialogActions>
                        </Form>
                    )}
                </Formik>}
            </DialogContent>

        </Dialog>)
}


const mapStateToProps = (state: any) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, { setSnackBar })(ForgotPasswordFormDialog)